/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; background-color: #ededed !important; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.text-primary-proforma { color: #1ABC9C;}

.c-c-w {
    width: calc(100% - 1px); /* La resta de 20px al 100% del ancho del contenedor */
    margin-left: 1px;
}

#header{
    background-color: #0093E9;
    background-image: linear-gradient(160deg,#f8f5ea 0%, #80D0C7 40%, #1ABC9C  90%);
    height: 30px;  
}


/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~   MENSAJE DE VALIDACIÓN  ~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
mat-form-field.content-error{
    position: relative !important;
    .msg-error{
        font-size: 11px;
        position: absolute;
        right: -10px;
        bottom: -4px;
        color: rgb(220,53,69);
        z-index: 999;
    }
}



/* Clase que elimina el padding en los mat-expansion-panel del mat-accordion */
.no-padding-accordion mat-expansion-panel {
    padding: 0; /* Elimina padding en el panel */
    div.mat-expansion-panel-content {
        div.mat-expansion-panel-body {
            padding: 0;
        }
    }    
} 
.no-padding-accordion mat-expansion-panel-header {
    padding: 0 11px; /* Elimina padding en el encabezado del panel */
    
}
.no-padding-accordion mat-expansion-panel-header.mat-expanded {
    background-color: #f0f0f0;
}
.no-padding-accordion .mat-expansion-panel-content {
    padding: 0; /* Elimina padding en el contenido del panel */
}



.calculated-concept-mat-icon {
    font-size: 16px; /* Ajusta este valor para hacer el ícono más pequeño */
    color: #008777; /* Color personalizado */
}
  